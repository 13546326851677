import { Sheet, SheetRef } from "react-modal-sheet";
import PlateImage from "./PlateImage";
import { DishInfo, TagReads } from "../types/Tags";
import TagBox from "./TagBox";
import { useRef } from "react";
import { getBeltTime, timeToSeen } from "../App";

export default function BottomModal({ open, close, plates, tagID, ourTime }: {
  open: boolean;
  close: () => void;
  plates: TagReads[];
  tagID: string;
  ourTime: number;
}) {
  const ref = useRef<SheetRef>();
  // const snapTo = (i: number) => ref.current?.snapTo(i);
  const plate = plates.find(p => p.tagID === tagID);

  if (plate === undefined)
    return (
      <></>);

  const { dishData, timesSeen } = plate;
  const others = plates.filter(p => (p.dishData?.name === dishData?.name));

  return (
    <Sheet
      isOpen={open}
      ref={ref}
      onClose={close}
      snapPoints={[0.6]}
    >
      <Sheet.Container style={{
        borderTopLeftRadius: '50px',
        borderTopRightRadius: '50px',
        backgroundColor: 'white',
      }}>
        <Sheet.Header />
        <Sheet.Content>
          {dishData ? (
            <div style={{
              display: 'flex', flexDirection: 'column', alignItems: 'center',
              justifyContent: 'start',
              paddingTop: '20px'
            }}>
              <PlateImage
                color={dishData.color}
                size={100}
                percent={timeToSeen(timesSeen, new Date().getTime(), ourTime) / getBeltTime(timesSeen)}
                dishID={dishData.id}
              />
              <div style={{
                flexDirection: 'row',
                display: 'flex',
                gap: '10px',
                alignItems: 'start',
                marginTop: '40px'
              }}>
                <p style={{ fontWeight: 'bold', fontSize: '22px', margin: '0px' }}>{dishData.name}</p>
                <p style={{ color: '#777', fontSize: '20px', margin: '0px' }}>
                  ({others.findIndex(p => p.tagID === tagID) + 1} of {others.length})
                </p>
              </div>
              <div style={{
                flexDirection: 'row',
                display: 'flex',
                marginTop: '4px',
                alignItems: 'end',
                color: '#7E8494'
              }}>
                <p>{(dishData.ingredients ?? []).join(', ')}</p>
              </div>

              <div style={{
                display: 'flex',
                gap: '8px',
                marginTop: '8px'
              }}>
                {(dishData.tags ?? []).map((tag) => {
                  return <TagBox tag={tag} padding={5} />;
                })}
              </div>

              <div
                style={{
                  backgroundColor: '#00A69C',
                  display: 'flex',
                  margin: '20px',
                  color: 'white',
                  padding: '15px',
                  borderRadius: '30px',
                  width: '70%',
                  justifyContent: 'center',
                }}
                onClick={close}
              >
                Close
              </div>
            </div>
          ) : (<div>Loading...</div>)}
        </Sheet.Content>
      </Sheet.Container>
      <Sheet.Backdrop />
    </Sheet>
  );
}