import React from 'react';
import { TagReads } from '../types/Tags';
import PlateImage from './PlateImage';
import TagBox from './TagBox';
import { getBeltTime, timeToSeen } from '../App';

const Plate = ({ tagID, timesSeen, dishData, plates, ourTime }: TagReads & {
  plates: TagReads[];
  ourTime: number;
}) => {
  if (!dishData) {
    return <div>Loading...</div>;
  }

  const ingredientList = (dishData.ingredients ?? []).join(', ');
  const others = plates.filter(p => (p.dishData?.name === dishData.name));

  return (
    <div style={{
      display: 'flex',
      backgroundColor: 'white',
      // height: '100px',
      flexDirection: 'row',
      alignItems: 'center',
      borderTop: '1px solid #E5E7EF',
      padding: '12px'
    }}>
      <div style={{
        justifyContent: 'start',
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
        // position: 'relative'
        margin: '15px'
      }}>
        <PlateImage
          color={dishData.color ?? 'red'}
          size={50}
          percent={timeToSeen(timesSeen, new Date().getTime(), ourTime) / getBeltTime(timesSeen)}
          dishID={dishData.id}
        />
      </div>
      <div style={{
        flexDirection: 'column',
        display: 'flex',
        flex: 1,
        height: '100%'
      }}>
        <div style={{
          flexDirection: 'row',
          display: 'flex',
          gap: '10px',
          alignItems: 'start',
        }}>
          <p style={{ fontWeight: 'bold', fontSize: '1rem', margin: '0px' }}>{dishData.name}</p>
          <p style={{ color: '#777', margin: '0px' }}>
            ({others.findIndex(p => p.tagID === tagID) + 1} of {others.length})
          </p>
        </div>

        <div style={{
          flexDirection: 'row',
          display: 'flex',
          marginTop: '4px',
          alignItems: 'end',
          color: '#7E8494'
        }}>
          <p>{ingredientList}</p>
        </div>

        {/* <div>
          {timeToSeen(timesSeen, new Date().getTime(), ourTime) / 1000}
        </div> */}

        <div style={{
          display: 'flex',
          gap: '8px',
          marginTop: '8px'
        }}>
          {(dishData.tags ?? []).map((tag) => {
            return <TagBox tag={tag} key={tag} />;
          })}
        </div>
      </div>
    </div>
  );
};

export default Plate;