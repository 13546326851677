import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Tag, TagFilter } from "../types/Tags";
import { Button } from "@mui/material";


export default function TagButton({
  tag, tagFilters, setTagFilters
}: {
  tag: Tag | 'All';
  tagFilters: TagFilter;
  setTagFilters: Dispatch<SetStateAction<TagFilter>>;
}) {
  const [selected, setSelected] = useState(false);

  useEffect(() => {
    setSelected(tag === 'All' ? !Object.values(tagFilters).some((bool) => bool) : tagFilters[tag]);
  }, [tagFilters, tag]);

  return (
    <button
      key={tag}
      style={{
        backgroundColor: selected ? '#00A69C' : '#E5E7EF',
        borderRadius: '32px',
        fontSize: '14px',
        color: selected ? 'white' : '#565C69',
        display: 'inline-block',
        padding: '7px 14px',
        border: '1px solid black'
      }}
      onClick={() => {
        setTagFilters((prev: TagFilter) => {
          if (tag === 'All') {
            return { Raw: false, Spicy: false, Cooked: false, Vegi: false, "Gluten Free": false };
          }
          console.log(prev);
          prev[tag] = !prev[tag];
          console.log(prev);
          return { ...prev };
        });
      }}
    >
      {tag.replace('Gluten Free', 'GF')}
    </button>
  );
}