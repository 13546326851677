import { useEffect, useState } from 'react';
import { Tag, TagReads } from './types/Tags';
import Header from './components/Header';
import BottomModal from './components/BottomModal';
import API from './helpers/API';
import DishHelper from './helpers/DishHelper';
import PlateList from './components/PlateList';
import { tableTimes } from './helpers/TableTimes';

export function getBeltTime(timesSeen: number[]) {
  return timesSeen.length <= 1 ? (789150) : timesSeen[1] - timesSeen[0];
}


export function timeToSeen(timesSeen: number[], currentTime: number, timeForward: number) {
  if (timesSeen.length === 0) {
    return Infinity;
  }

  const beltTime = getBeltTime(timesSeen);
  const endSesh = (timesSeen[timesSeen.length - 1]) + timeForward;
  const timeUntil = (endSesh - currentTime + beltTime) % beltTime;
  return timeUntil;
}

function App() {
  const [table, setTable] = useState<number>(1);
  const [ourTime, setOurTime] = useState<number>(0);

  const [plates, setPlates] = useState<TagReads[]>([]);
  const [displayPlates, setDisplayPlates] = useState<TagReads[]>(plates);
  const [tagFilters, setTagFilters] = useState<{ [tag in Tag]: boolean }>({ Raw: false, Spicy: false, Cooked: false, Vegi: false, "Gluten Free": false });

  const [seconds, setSeconds] = useState(0);
  const [startTime] = useState(new Date().getTime());
  const [dishHelper, setDishHelper] = useState<DishHelper | null>(null);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let intervalID: NodeJS.Timeout;

    (async () => {
      setDishHelper(await DishHelper.getData());

      intervalID = setInterval(() => {
        setSeconds(seconds => seconds + 1);
      }, 1000);
    })();

    return () => clearInterval(intervalID);
  }, []);

  useEffect(() => {
    if (!dishHelper) {
      console.log("Waiting for dishHelper...");
      return;
    }

    if (seconds % 20 === 0 || plates.length === 0) {
      API.getPlates().then((plates) => {
        plates = plates.filter((plate) => plate.timesSeen.length > 0);
        plates = plates.map((plate) => {
          const dishID = dishHelper.getDishID(plate.tagID);
          const dishData = dishHelper.getDishData(dishID);

          plate.dishData = dishData;
          return plate;
        });
        plates = plates.sort((a, b) => {
          return timeToSeen(a.timesSeen, startTime + seconds * 1000, ourTime) - timeToSeen(b.timesSeen, startTime + seconds * 1000, ourTime);
        });

        setPlates(plates);
        setLoading(false);
      });
    }
  }, [seconds, startTime]);

  const [modalProps, setModalProps] = useState<null | TagReads>(null);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const tableParam = queryParams.get('table');
    if (tableParam && !isNaN(Number(tableParam))) {
      setTable(Number(tableParam));
      setOurTime(() => {
        const newTime = tableTimes
          .filter((_, i) => i < Number(tableParam))
          .reduce((a, b) => a + b, 0) * 1000;
        console.log('Time from reader: ' + newTime);
        return newTime;
      });
    }
  }, []);

  useEffect(() => {
    const adjustHeight = () => {
      document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);
    };

    window.addEventListener('resize', adjustHeight);
    adjustHeight();

    document.title = 'Wasabi Live'

    return () => window.removeEventListener('resize', adjustHeight);
  }, []);

  useEffect(() => {
    setDisplayPlates(plates.filter((plate) => {
      return !Object.keys(tagFilters).some((tag) => {
        return tagFilters[tag as Tag] && !(plate.dishData?.tags ?? []).includes(tag as Tag);
      });
    }));
  }, [plates, tagFilters])

  return (
    <>
      <div style={{
        display: 'flex',
        // padding: '5px',
        flexDirection: 'column',
        height: 'calc(var(--vh, 1vh) * 100)',
        overflowY: 'scroll'
      }}>
        <Header tagFilters={tagFilters} setTagFilters={setTagFilters} table={table} plates={plates.length} />
        <PlateList
          displayPlates={displayPlates}
          setModalProps={setModalProps}
          allPlates={plates}
          loading={loading}
          ourTime={ourTime}
        />
      </div>
      <BottomModal
        open={modalProps !== null}
        close={() => { setModalProps(null); }}
        plates={plates}
        tagID={modalProps?.tagID ?? ''}
        ourTime={ourTime}
      />
    </>
  );
}

export default App;