import { PlateColor } from "../types/Tags";

export default class Colors {
  static tagColors = {
    Cooked: {
      dark: "#FF3B2D",
      light: "#FFE6E4",
    },
    Spicy: {
      dark: "#FF3B2D",
      light: "#FFE6E4",
    },
    Vegi: {
      dark: "#00A69C",
      light: "#E6FFFE",
    },
    "Gluten Free": {
      dark: "#35166E",
      light: "#F2EDFC",
    },
    Raw: {
      dark: "#35166E",
      light: "#F2EDFC",
    },
  };

  static colors: { [key in PlateColor]: number[] } = {
    red: [255, 0, 0],
    orange: [255, 165, 0],
    yellow: [232, 223, 118],
    white: [150, 150, 150],
    purple: [189, 70, 128],
    blue: [80, 138, 226],
  };

  public static getColor(colorName: PlateColor) {
    return (
      "#" +
      (Colors.colors[colorName] ?? [0, 0, 0])
        .map((x) => x.toString(16).padStart(2, "0"))
        .join("")
    );
  }

  public static darkenColor(
    colorName: PlateColor,
    factor: number,
    alpha: number
  ) {
    if (!Colors.colors[colorName]) {
      colorName = "red";
    }

    const clampedAlpha = Math.max(0, Math.min(1, alpha));

    // console.log(colorName, Colors.colors[colorName]);

    const darkenedRGB = Colors.colors[colorName].map((component) =>
      Math.round(component * factor)
    );

    const darkenedHex =
      "#" +
      darkenedRGB
        .map((x) => {
          const hex = x.toString(16);
          return hex.length === 1 ? "0" + hex : hex;
        })
        .join("") +
      Math.round(clampedAlpha * 255)
        .toString(16)
        .padStart(2, "0");

    return darkenedHex;
  }
}
