import Colors from "../helpers/Colors";
import { Tag } from "../types/Tags";

import { PiPlant } from "react-icons/pi"; // Vegi
import { PiPepper } from "react-icons/pi"; // Spicy
import { LuMilk } from "react-icons/lu"; //Gluten Free
import { GoFlame } from "react-icons/go"; // Hot
import { MdRawOn } from "react-icons/md"; // Raw
import React from "react";

const tagIconMapping = {
  Vegi: PiPlant,
  Spicy: PiPepper,
  'Gluten Free': LuMilk,
  Cooked: GoFlame,
  Raw: MdRawOn,
};

export default function TagBox({ tag, padding = 0 }: { tag: Tag; padding?: number; }) {
  if (!(tag in tagIconMapping)) {
    console.error(`Tag ${tag} not found in tagIconMapping`);
    return null;
  }

  return (
    <div key={tag} style={{
      borderRadius: '4px',
      fontSize: '12px',
      backgroundColor: Colors.tagColors[tag].light,
      color: Colors.tagColors[tag].dark,
      display: 'inline-block',
      padding: `${padding + 2}px ${padding + 4}px`,
      border: `1px solid ${Colors.tagColors[tag].dark}`,
    }}>
      {React.createElement(tagIconMapping[tag], { style: { marginRight: '4px' } })}
      {tag}
    </div>
  );
}