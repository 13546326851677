import { Dispatch, SetStateAction } from "react";
import TagButton from "./TagButton";
import { Tag, TagFilter } from "../types/Tags";

export default function Header({
  setTagFilters,
  tagFilters,
  table,
  plates,
}: {
  setTagFilters: Dispatch<SetStateAction<TagFilter>>;
  tagFilters: TagFilter;
  table: number;
  plates: number;
}) {
  const tags: (Tag | 'All')[] = ['All', 'Raw', 'Spicy', 'Vegi', 'Cooked', 'Gluten Free']

  return (
    <div style={{ padding: '20px' }}>
      <h2>Wasabi Live View</h2>
      <p style={{ color: 'gray', paddingTop: '8px' }}>Experience a live view of the Wasabi Restaurant conveyor belt in action.</p>

      <div style={{ display: 'flex', flexDirection: 'row', marginTop: '15px', gap: '16px' }}>
        <div style={{
          flex: 1,
          backgroundColor: '#35166E',
          color: 'white',
          padding: '12px',
          paddingLeft: '16px',
          borderRadius: '16px'
        }}>
          <h2>{table}</h2>
          <p style={{ fontSize: '12px' }}>Table Number</p>
        </div>
        <div style={{
          flex: 1,
          backgroundColor: '#E6FFFE',
          color: '#006F68',
          padding: '12px',
          paddingLeft: '16px',
          borderRadius: '16px'
        }}>
          <h2>{plates === 0 ? 'Loading...' : plates}</h2>
          <p style={{ fontSize: '12px' }}>Items on the belt</p>
        </div>
      </div>

      <div style={{
        display: 'flex',
        flexDirection: 'row',
        gap: '4px',
        marginTop: '16px',
        // flexWrap: 'wrap',
        justifyContent: 'center',
        overflowY: 'auto'
      }}>
        {
          tags.map((tag, index) => {
            return (
              <TagButton
                key={tag}
                tag={tag}
                tagFilters={tagFilters}
                setTagFilters={setTagFilters}
              />
            );
          })
        }
      </div>

      {/* <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginTop: '15px' }}>
        <h4 style={{ color: '#7E8494', fontSize: '16px' }}>267 items</h4>
        <div style={{ backgroundColor: '#EEEEEE', padding: '3px 3px 0px 3px', borderRadius: '30px', display: 'flex', alignItems: 'center' }}>
          <button onClick={() => setStack((old) => !old)} style={{ border: 'none', background: 'none', borderRadius: '100%' }}>
            <GoStack size={25} style={stack ? selectedButtonStyle : unselectedButtonStyle} />
          </button>
          <button onClick={() => setStack((old) => !old)} style={{ border: 'none', background: 'none', borderRadius: '100%' }}>
            <FaListUl size={25} style={stack ? unselectedButtonStyle : selectedButtonStyle} />
          </button>
        </div>
      </div> */}
    </div>);
}