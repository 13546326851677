import { FaArrowUp } from "react-icons/fa";
import { timeToSeen } from "../App";
import { TagReads } from "../types/Tags";
import { Dispatch, SetStateAction, useRef } from "react";
import Plate from "./Plate";
import LoadingBar from "./LoadingBar";

export default function PlateList({ allPlates, displayPlates, setModalProps, loading, ourTime }: {
  allPlates: TagReads[];
  displayPlates: TagReads[];
  setModalProps: Dispatch<SetStateAction<TagReads | null>>;
  loading: boolean;
  ourTime: number;
}) {
  const listRef = useRef<HTMLDivElement>(null);

  const scrollToTop = () => {
    if (listRef.current) {
      listRef.current.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  };

  return !loading ? (
    <>
      <div ref={listRef} style={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        // overflowY: 'scroll',
      }}>
        {
          displayPlates?.sort((a, b) => {
            const timeA = timeToSeen(a.timesSeen, new Date().getTime(), ourTime);
            const timeB = timeToSeen(b.timesSeen, new Date().getTime(), ourTime);

            if (timeA < 0 && timeB < 0) {
              return 0;
            } else if (timeA < 0) {
              return 1;
            } else if (timeB < 0) {
              return -1;
            } else {
              return timeA - timeB;
            }
          }).map((plate) => {
            return (
              <div onClick={() => setModalProps(plate)} key={plate.tagID}>
                <Plate {...plate} plates={allPlates} ourTime={ourTime} />
              </div>
            );
          })
        }
      </div>

      {/* <button onClick={scrollToTop} style={{
        position: 'fixed',
        bottom: '20px',
        right: '20px',
        borderRadius: '100%',
        border: 'none',
        background: '#35166E'
      }}>
        <FaArrowUp size={30} style={{ color: 'white', margin: '15px' }} />
      </button> */}
    </>
  ) : (
    <div style={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center', fontSize: '20' }}>
      <LoadingBar />
    </div>
  );
}