import Colors from "../helpers/Colors";
import { PlateColor } from "../types/Tags";

export default function PlateImage({ color, size, percent, dishID }: {
  color: PlateColor;
  size: number;
  percent: number;
  dishID: string;
}) {
  const imageSize = size;
  const decorationSize = size * 1.3;
  let fillDegree = percent * 360;

  while (fillDegree > 360) {
    fillDegree -= 360;
  }

  fillDegree = 360 - fillDegree;

  const mm = (decorationSize - imageSize) / 2;

  const x = Math.sin(fillDegree * Math.PI / 180) * (decorationSize / 2) * 0.95;
  const y = -Math.cos(fillDegree * Math.PI / 180) * (decorationSize / 2) * 0.95;

  return (
    <div style={{
      display: 'flex',
      position: 'relative'
    }}>
      <img src={`/images/${dishID}.jpg`}
        alt=""
        style={{
          width: `${imageSize}px`,
          height: `${imageSize}px`,
          objectFit: 'cover',
          borderRadius: '50%',
        }} />
      <div style={{
        position: 'absolute',
        background: `conic-gradient(${Colors.getColor(color)} 0deg ${fillDegree}deg, ${Colors.darkenColor(color, .7, .2)} ${fillDegree}deg 360deg)`,
        //Proportional thickness
        mask: `radial-gradient(farthest-side, transparent ${decorationSize / 2 - decorationSize * 0.05}px, black ${decorationSize / 2 - decorationSize * 0.05 + 1}px, black ${decorationSize / 2 - 1}px, transparent ${decorationSize / 2}px)`,
        width: `${decorationSize}px`,
        height: `${decorationSize}px`,
        margin: `${-mm}px 0px 0px ${-mm}px`,
        zIndex: 2,
      }} />
      <div
        style={{
          position: 'absolute',
          width: size / 7,
          height: size / 7,
          backgroundColor: Colors.getColor(color),
          borderRadius: '100%',
          // border: '2px solid white',
          margin: `${(imageSize - size / 10) / 2 + y - 1}px 0px 0px ${-1 + (imageSize - size / 10) / 2 + x}px`,
        }}
      />
    </div>
  )
}